.contactPage{
    margin-top: 80px;
}

.contactPage h4{
    font-size: 24px;
    color: #fff;
    letter-spacing: 3px;
}

.contactPage span{
    color: #2F89FC;
}

.contactPage form input{
    width: 100%;
    border: 1px solid rgba(196, 196, 196, 0.62);
    background: transparent;
    margin-bottom: 30px;
    padding: 5px;
    font-size: 15px;
}

.contactPage form textarea{
    width: 100%;
    border: 1px solid rgba(196, 196, 196, 0.62);
    background: transparent;
    margin-bottom: 20px;
    padding: 5px;
    font-size: 15px;
}

.map{
    width: 200px;
    height: 200px;
}