.port ul{
    text-align: center;
    padding-top: 30px;
}

.port ul li{
    list-style: none;
    display: inline;
    margin-right: 65px;
    color: #fff;
    font-weight: normal;
    letter-spacing: 5px;
}

.port ul li a{
    text-decoration: none;
    color: #fff;
}

.port .active{
    border-bottom: 1px solid #D5C455;
    padding-bottom: 5px;
}

@media only screen and (max-width: 991px) {
    /* PORTFOLIO */
    .port{
        padding-top: 80px;
    }
    .port ul{
        padding: 0;
        padding-top: 20px;
    }
    .port ul li{
        font-size: 14px;
        margin-right: 15px;
    }
    .port .active{
        background: #D5C455;
        border-bottom: 1px solid #D5C455;
        padding: 8px;
    }
}