.react {
  width: 100%;
  margin: 0 auto;
}
.projects-card {
  position: relative;
  margin-bottom: 30px;
}

.description {
  background: #d5c455;
  padding: 5px 10px;
  /* margin-left: -10px;
    position: absolute;
    top: 180px; */
}

.description h5 {
  margin: 0;
  color: #fff;
  letter-spacing: 3px;
  font-size: 18px;
}

.description p {
  /* margin: 0;
    color: #212529;
    opacity: 0;
    font-size: 14px;
    letter-spacing: 3px; */
  display: none;
}

.description button {
  display: none;
}

.description::before {
  height: 20px;
}

.projects-card:hover .description {
  background: #121212;
  width: 100%;
  height: 100% !important;
  position: absolute;
  top: 0;
  opacity: 0.9;
  text-align: center;
  padding-top: 50px;
  animation: slideUp 0.7s ease;
}

.projects-card:hover p {
  display: block;
  color: #d5c455;
  text-align: center;
  padding-top: 10px;
}

.projects-card:hover button {
  display: block;
  text-align: center;
  margin: 0 auto;
}

.projects-card:hover button a {
  color: #fff;
  text-decoration: none;
}

.wrapper img {
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 90%;
  height: 100%;
  background: rgba(27, 27, 27, 0.541);
  display: flex;
  align-items: center;
  z-index: 999;
}

.overlay img {
  display: block;
  max-width: 80%;
  margin: 100px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}

.overlay > span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #fff;
  z-index: 999;
  cursor: pointer;
}

.overlay-arrow_left {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  left: 0;
  z-index: 999;
}

.overlay-arrow_left svg {
  width: 50px;
  height: 50px;
}

.overlay-arrow_right svg {
  width: 50px;
  height: 50px;
}

.overlay-arrow_right {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  left: 0;
  z-index: 999;
}

@keyframes slideUp {
  from {
    transform: translateY(100px);
  }
  to {
    transform: translateY(0);
  }
}

@media only screen and (min-width: 1300px) {
  .overlay img {
    max-width: 50%;
  }
}
