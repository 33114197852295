body {
  font-family: "Poppins", Arial, sans-serif;
  background: #121212;
  font-size: 18px;
  line-height: 1.8;
  font-weight: 400;
  color: #8c8c8c;
  position: relative;
  overflow-x: hidden !important;
}

body:after {
  position: fixed;
  top: -150px;
  right: -50px;
  bottom: 0;
  content: "";
  width: 400px;
  height: 600px;
  border: 2px solid rgba(255, 255, 255, 0.05);
  z-index: -1;
  -webkit-transform: rotate(-30deg);
  -moz-transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
  -o-transform: rotate(-30deg);
  transform: rotate(-30deg);
  overflow-x: hidden !important;
}

body:before {
  position: fixed;
  top: 500px;
  left: -100px;
  bottom: 0;
  content: "";
  width: 400px;
  height: 400px;
  border: 2px solid rgba(255, 255, 255, 0.05);
  z-index: -1;
  -webkit-transform: rotate(20deg);
  -moz-transform: rotate(20deg);
  -ms-transform: rotate(20deg);
  -o-transform: rotate(20deg);
  transform: rotate(20deg);
  overflow-x: hidden !important;
}

img {
  width: 100%;
}

.r-container {
  width: 80%;
  max-width: 900;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.hr {
  width: 4px;
  height: 20px !important;
  color: rgba(255, 255, 255, 0.7);
  opacity: 1;
  margin: 0 auto;
  margin-bottom: 8px;
}

.heading {
  text-align: center;
}
.heading h5 {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 5px;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.heading h2 {
  font-size: 35px;
  font-weight: bold;
  line-height: 30px;
  align-items: center;
  letter-spacing: 3px;
  color: #ffffff;
}

button {
  background-color: #d5c455;
  color: #fff;
  font-size: 14px;
  padding: 6px 30px;
  outline: none;
  border: none;
}

button a {
  color: #fff;
  text-decoration: none;
}

button a:hover {
  color: #fff;
}
/* Services section */
.services {
  position: relative;
  padding-top: 50px;
}

.services .services_shape {
  background-image: url(./assets/images/Group34.png);
  width: 100%;
  height: 160px;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  z-index: -1;
}

.services .services-card {
  background: #fff;
  opacity: 1;
  text-align: center;
  padding: 30px 15px;
  border-radius: 40px;
  margin: 60px 25px;
}

.services .services-card i {
  font-size: 40px;
  color: #d5c455;
  margin-bottom: 25px;
}

.services .services-card h4 {
  color: #212529;
  font-size: 20px;
  font-weight: bold;
}

.services .services-card p {
  font-size: 20px;
  line-height: 26px;
}

@media only screen and (max-width: 768px) {
  .r-container {
    width: 85%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 991px) {
  .services .services-card {
    padding: 20px 15px;
    margin: 30px 0;
  }

  .heading h5 {
    font-size: 14px;
  }

  .heading h2 {
    font-size: 25px;
  }
}
