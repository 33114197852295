.aboutPage {
  position: relative;
  margin-top: 100px;
}

.aboutPage img {
  width: 401;
  height: 600;
}

.aboutPage h1 {
  font-size: 160px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.1);
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  top: -70px;
  left: 50px;
  z-index: -1;
}

.aboutPage .about_me {
  position: relative;
  margin-right: 100px;
}

.aboutPage .about_me h5 {
  color: #d5c455;
  font-size: 14px;
  font-weight: bold;
}

.aboutPage .about_me h3 {
  color: #fff;
  font-size: 32px;
  font-weight: bold;
}

.aboutPage .about_me p {
  color: #fff;
  letter-spacing: 3px;
  line-height: 25px;
  padding-top: 20px;
}

.aboutPage .about_me .about_contact p {
  font-size: 14px;
  padding: 0;
  margin: 10px 0;
}

.aboutPage .about_me .about_contact p span {
  color: #d5c455;
}

/* COUNT DOWN SECTION */
.countDown {
  text-align: center;
  padding-top: 60px;
}

.countDown .c-container {
  width: 40%;
  margin: 0 auto;
}

.countDown .countCard {
  border: 1px solid #d5c455;
  border-radius: 20px;
  padding: 20px;
}

.countDown .countCard h2 {
  margin: 0;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
}

.countDown .countCard p {
  margin: 0;
  color: #fff;
  font-size: 14px;
}

/* SKILLS */
.skills {
  padding-top: 180px;
}

.skills-wrappers {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.skills-items {
  margin: 50px 5px;
}

.skills-items h6 {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  margin: 10px auto;
  line-height: 1.3px;
}

.skills-items .skills-progress {
  background-color: #ffffff;
  border-radius: 20px;
  height: 5px;
}

.skills-items .skills-progress-bar {
  background-color: #d5c455;
  height: 5px;
  position: relative;
}

.skills-items .skills-progress span {
  position: absolute;
  top: -24px;
  right: 0;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
}

/* PROJECT BAR CONTENT */

.javascript {
  width: 100%;
}

.html {
  width: 90%;
}

.css {
  width: 90%;
}

.reactjs {
  width: 74%;
}

.angular {
  width: 60%;
}

.figma {
  width: 75%;
}

.photoshop {
  width: 80%;
}

.illustrator {
  width: 80%;
}

.bootstrap {
  width: 85%;
}

.nodejs {
  width: 45%;
}

.seo {
  width: 60%;
}

.git {
  width: 70%;
}

/* TOOLS */
.tools {
  background: #fff;
  padding: 100px 0;
  margin-top: 200px;
}

.tools .heading .hr {
  color: rgba(33, 37, 41, 0.7);
}

.tools .heading h5 {
  color: rgba(33, 37, 41, 0.6);
}

.tools .heading h2 {
  color: #212529;
}

.tools h1 {
  color: black;
}

.tools .tools_slider {
  margin-top: 80px;
}

.tools .tools_slider .slider_item img {
  width: 120px;
  height: 80px;
  margin: 0 auto;
}

.experience {
  margin-top: 120px;
}

.container-e {
  width: 70%;
  margin: 0 auto;
}

.experience-content {
  padding-bottom: 25px;
  border-bottom: 1px solid #8c8c8c;
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .aboutPage .about_me {
    margin-right: 0;
  }
  .container-e {
    width: 90%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 765px) {
  .aboutPage .img {
    width: 100%;
    justify-content: flex-end;
  }
  .aboutPage h1 {
    font-size: 90px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.1);
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    top: -65px;
    left: 0;
    z-index: -1;
  }
  .aboutPage .about_me h5 {
    padding-top: 20px;
  }
}

.slider_item img {
  width: 100%;
  height: auto;
  object-fit: contain; /* or use 'cover' depending on your preference */
}
